import React from 'react';
import Card from 'react-bootstrap/Card';

const WaitingComponent = () => {
    return (
        <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', width: '18rem', margin: '0 auto', padding: '20px' }}>
            <Card.Body>
                <Card.Text>
                    Waiting for other players to guess...
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default WaitingComponent;
