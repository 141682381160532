import React from 'react';
import { Card } from 'react-bootstrap';

const Scoreboard = ({ players, bestAnswerBoard }) => {
    if (!players) {
        return (
            <Card>
                <Card.Header>Scoreboard</Card.Header>
                <Card.Body>Loading...</Card.Body>
            </Card>
        );
    }

    const title = bestAnswerBoard ? "Style Points" : "'Real' Points";
    const pointsLabel = bestAnswerBoard ? "bestAnswerPoints" : "correctGuessPoints";

    return (
        <Card>
            <Card.Header>{title}</Card.Header>
            <Card.Body>
                {/* Placeholder content */}
                <Card.Text>
                     {/*Render player names and points */}
                    {players.map((player, index) => (
                        <span key={index}>
                            <b>{player.name}:</b> {player[pointsLabel]}
                            <br />
                        </span>
                    ))}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default Scoreboard;

