const answers = [
    "Bananas are native to Antarctica.",
    "The number 42 is the atomic number of hydrogen.",
    "Purple is a type of metal.",
    "Three o'clock is the time when the sun sets.",
    "France is known for its pyramids.",
    "Carrots are a type of seafood.",
    "Jumping jacks were invented in ancient Rome.",
    "Mount Everest is located in Antarctica.",
    "Iron Man is a real-life inventor.",
    "Mars is made entirely of cheese.",
    "Soccer is played with a cube-shaped ball.",
    "Pineapples grow underground.",
    "Shakespeare was a time traveler.",
    "Elephants can fly.",
    "Sunrise happens at midnight."
];


export function jesusTakeTheWheel() {
    return answers[Math.floor(Math.random() * answers.length)];
}