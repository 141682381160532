// Header.jsx
import {getRandomOpenerImage} from "../utils";

import { useState } from 'react';

import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';


const Home = ({ app, db }) => {
    const navigate = useNavigate();
    const [joinValue, setJoinValue] = useState('');
    const [bgImage] = useState(`url('${process.env.PUBLIC_URL}/img/${getRandomOpenerImage()}')`);





    const handleCreateGame = async () => {
        console.log('create game');

        // Generate a six-digit code
        const code = Math.floor(100000 + Math.random() * 900000);

        // Add the game to the Firestore collection
        const gamesCollection = collection(db, 'games');
        console.log('collection', gamesCollection);
        const gameRef = await addDoc(gamesCollection, {
            code: code,
            created: serverTimestamp(),
            players: [],
            questions: [],
            phase1_rounds: 5,
            gameState: {
                phase: 'open',
                currentQuestion: '',
                round: 0,
                whoseGuess: 0,
                currentResponses: [],
                allResponsesIn: false,
            },
        });

        console.log(`Game created with code ${code}`);
        navigate(`/game/${gameRef.id}`);
    };

    const handleJoinGame = (e) => {
        e.preventDefault();
        navigate(`/join/${joinValue}`);
    };

    return (
        <header
            style={{
                alignItems: 'center',
                backgroundImage: bgImage,
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                minHeight: '100vh',
            }}
        >
            <div style={{ background: 'rgba(255, 255, 240, 0.8)', padding: '20px' }}>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <h1 style={{ color: 'navy' }}>The Fucking Catalina Wine Mixer</h1>
                            <div className="text-center" style={{ marginBottom: '20px' }}>
                               <Button
                                    variant="warning"
                                    onClick={handleCreateGame}
                                    style={{ marginBottom: '20px' }}
                                >
                                    Create Game
                                </Button>
                                <Form onSubmit={handleJoinGame}>
                                    <Form.Group controlId="formJoin">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter game code"
                                            value={joinValue}
                                            onChange={(e) => setJoinValue(e.target.value)}
                                            style={{ marginBottom: '20px' }}
                                        />
                                    </Form.Group>
                                    <Button variant="warning" type="submit" style={{ marginBottom: '20px' }}>
                                        Join Game
                                    </Button>
                                    <br />
                                    <Link to="/rules">
                                        <Button variant="warning" type="submit">
                                            Rules
                                        </Button>
                                    </Link>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </header>
    );
};

export default Home;

