import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {Card,
    Container,
    Col,
    Row} from 'react-bootstrap';

import { collection, query, where, onSnapshot } from 'firebase/firestore';

import Phase1MC from "./Phase1MC";
import Phase1Chump from "./Phase1Chump";
import Phase2Chump from "./Phase2Chump";
import EndGame from "./EndGame/EndGame"
import {getRandomHalftimeImage} from "../utils";

const Play = ({app, db}) => {
    const { playerId } = useParams();

    const [game, setGame] = useState(null);

    const [imMC, setImMC] = useState(false);
    const [bgHalftimeImage] = useState(`url('${process.env.PUBLIC_URL}/img/${getRandomHalftimeImage()}')`);

    useEffect(() => {
        const gamesCollection = collection(db, 'games');
        const q = query(gamesCollection, where('playerIDs', 'array-contains', playerId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            console.log('Play.jsx: read')
            querySnapshot.forEach((doc) => {
                const gameData = doc.data();

                // Update game state
                setGame({
                    id: doc.id,
                    ...gameData
                });

                // Check if the player is the host (MC)
                if (gameData.playerIDs[gameData.gameState.mc] === playerId) {
                    setImMC(true);
                } else {
                    setImMC(false);
                }
            });
        });

        return unsubscribe;
    }, [playerId, db]);

    if (game === null) {
        return <p>Loading game data...</p>;
    }


    if (game.gameState.phase === 'open') {
        return (
            <header style={{
                backgroundImage: 'url("/img/catalina-hold-screen1.png")',
                minHeight: '1000px',
                display: 'flex',
                justifyContent: 'top',
                alignItems: 'top',
                backgroundSize: 'cover'
            }}>
                <Container>
                    <h1 style={{color: 'darkslategray'}}>Please Hold On!</h1>
                    <h4 style={{color: 'darkslategrey'}}>Others are still joining</h4>
                    <h4 style={{color: 'darkslategrey'}}>So far we have:</h4>
                    <Row>
                    {game.players.map((player) => (
                        <Col key={player.id}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{player.name}</Card.Title>
                                    <Card.Text>{player.email}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                </Container>
            </header>
        );
    }
    else if (game.gameState.phase === "phase1") {
        if (imMC) {
            return (
                <header style={{
                    backgroundImage: 'url("/img/mc2.png")',
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                }}>
                    <Phase1MC gameID={game.id}
                              playerID={playerId}
                              isMC={true}
                              app={app}
                              db={db}
                              gameData={game}/>
                </header>
                )

        }
        return (
            <Phase1Chump gameID={game.id} playerId={playerId} isMC={false} app={app} db={db}/>
        );
    }
    else if (game.gameState.phase === "halftime") {
        return (
            <header style={{
                backgroundImage: bgHalftimeImage,
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundSize: 'cover',
                backgroundPosition: 'center center'
            }}>
                <div style={{ background: 'rgba(255, 255, 255, 0.8)', padding: '20px' }}>
                    <Container>
                        <Card>
                            <Card.Title>
                                <h1>Halftime</h1>
                            </Card.Title>
                            <Card.Body>
                                Dust off, time to rally.
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </header>
        )

    }
    else if (game.gameState.phase === "phase2") {
        if (imMC) {
            return (
                <header style={{
                    backgroundImage: 'url("/img/mc1.png")',
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundSize: 'cover'
                }}>
                    <Phase1MC gameID={game.id}
                              playerID={playerId}
                              isMC={true}
                              app={app}
                              db={db}
                              gameData={game}/>
                </header>
            )

        }
        return (
            <Phase2Chump gameID={game.id}
                         playerId={playerId}
                         isMC={false}
                         app={app}
                         db={db}
                         gameData={game}/>
        );
    }
    else if (game.gameState.phase === "endgame") {
        return (
            <EndGame gameData = {game}
                playerId = {playerId} />
        );
    }
     return (
        <header>
            <Container>
                <h1 style={{color: 'darkslategray'}}>{game.gameState.phase}</h1>
                If you're here, you've found a bug.
            </Container>
        </header>
    );
};

export default Play;
