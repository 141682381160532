// ResponseCard.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward } from '@fortawesome/free-solid-svg-icons'

const ResponseCard = ({ response,
                          allResponsesIn,
                          isMC,
                          showButtons,
                          handleBestAnswer,
                          handleGuess,
                          responses,
                          subphase,
                      gameData}) => {






    if (allResponsesIn) {
        function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        }
        let answerResponses = []
        if (responses !== undefined) {
            answerResponses = responses
            shuffleArray(answerResponses)
        }
        // console.log('Response Card allResponsesIn response:', response)
        return (
            <Card style={{margin: '10px'}}>
                <Card.Body>
                    {response.bestAnswer && (
                        <FontAwesomeIcon icon={faAward}
                                         color="blue"
                                         style={{margin: '5px'}}/>
                    )}
                    {response.response}
                    <br/>
                    {response.correctlyGuessed && (
                        <span>{response.playerName}</span>
                    )}
                    {(subphase === "chooseBestResponse") && isMC && showButtons && (
                        <>
                            <Button variant="primary"
                                    onClick={() =>
                                gameData.gameState.phase === "phase1"
                                    ? handleBestAnswer(response.playerID)
                                    : handleBestAnswer(response.playedByInPhase2)
                                    }>
                                Best Answer
                            </Button>
                        </>
                    )}
                    {(subphase === "chooseWhoWroteWhat") && showButtons && (
                        <>

                            {!response.correctlyGuessed && (
                                gameData.players.map((player, i) => (
                                    // !r.correctlyGuessed ? (
                                        <Button key={i}
                                                variant="primary"
                                                onClick={() => handleGuess(player.id, response)}
                                                style={{ marginRight: '10px' }}>
                                            {player.name}
                                        </Button>
                                    // ) : null
                                ))
                            )}
                        </>
                    )}

                    {!allResponsesIn && (
                        <>
                            {response.playerName}
                        </>
                    )}
                </Card.Body>
            </Card>
        );
    } else {
        return ( <></> );
    }
};

export default ResponseCard;
