import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import Scoreboard from '../Scoreboard/Scoreboard';
import ResponseCard from '../ResponseCard/ResponseCard';

import {
    // collection,
    doc,
    onSnapshot,
    updateDoc
} from 'firebase/firestore';
import {
    advanceNextRound,
    getRandomImage,
    getSubPhase
} from "../../utils";

// Define the Phase1 component
const MainScreen = ({ players, app, db, gameId}) => {

    const [backgroundImage, setBackgroundImage] = useState('phase1.png');
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(null);
    const [currentRound, setCurrentRound] = useState(0);
    const [currentGuess, setCurrentGuess] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(null);

    const [allResponsesIn, setAllResponsesIn] = useState(false);
    const [responses, setResponses] = useState([]);
    const [subPhase, setSubPhase] = useState("not loaded");
    const [gameData, setGameData] = useState({});
    const [gameRefState, setGameRefState] = useState(null);
    const [phase, setPhase] = useState("")

    useEffect(() => {
        if (subPhase === "roundOver") {
            const newImage = getRandomImage();
            console.log('newImage', newImage);
            setBackgroundImage(newImage);
            console.log("roundOver")
            advanceNextRound(gameRefState, gameData)


        }
    }, [gameData,gameRefState,subPhase]);

    useEffect( () => {
        if (subPhase === "composeQuestion" && allResponsesIn) {
            updateDoc(gameRefState, {
                "gameState.allResponsesIn": false,
            });
            setAllResponsesIn(false);
        }
    },  [subPhase, allResponsesIn, gameRefState]);

    useEffect(() => {
        const gameRef = doc(db, 'games', gameId); // Reference to the specific gameId document
        setGameRefState(gameRef);
        const unsubscribe = onSnapshot(gameRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const gameData = docSnapshot.data();
                setGameData(gameData);
                setSubPhase(getSubPhase(gameData.gameState));
                if (gameData.gameState.phase) {
                    setPhase(gameData.gameState.phase)
                } else {
                    setPhase("Loading...")
                }

                // Check if the MC has changed
                if (gameData.gameState.mc !== currentPlayerIndex) {
                    // Clear the responses when the MC changes
                    setResponses([]);
                }

                setCurrentPlayerIndex(gameData.gameState.mc);
                setCurrentRound(gameData.gameState.round);

                if (gameData.players && gameData.players.length > gameData.gameState.whoseGuess) {
                    const currentGuessName = gameData.players[gameData.gameState.whoseGuess].name;
                    setCurrentGuess(currentGuessName);
                } else {
                    setCurrentGuess("Unknown");
                }

                setCurrentQuestion(gameData.gameState.currentQuestion);

                const responseLenModifier = gameData.gameState.phase === "phase2" ? 1 : 0;

                if (gameData.gameState.currentResponses &&
                    gameData.gameState.currentResponses.length === gameData.playerIDs.length - responseLenModifier  ){
                    // Update the Firestore document with allResponsesIn set to true
                    updateDoc(gameRef, {
                        "gameState.allResponsesIn": true,
                    })
                        .then(() => {
                            // Update the local state
                            setAllResponsesIn(true);
                            setResponses(gameData.gameState.currentResponses);
                        })
                        .catch((error) => {
                            console.error('Error updating game:', error);
                        });
                } else {

                }
            } else {

            }
        });

        return unsubscribe;
    }, [gameId, currentPlayerIndex, db, allResponsesIn, subPhase]);

    if (currentPlayerIndex === null) {
        return <div>Loading...</div>;
    }

    const currentPlayer = players[currentPlayerIndex];

    return (
        <div
            style={{
                backgroundImage: `url("/img/${backgroundImage}")`,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                padding: '20px',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
            }}
        >
            <div className="d-flex justify-content-between w-100">
                <div>
                    <Scoreboard players={players} bestAnswerBoard={true} />
                </div>
                <div>
                    <Scoreboard players={players} bestAnswerBoard={false}/>
                </div>
            </div>

            {allResponsesIn && (
                <div className="d-flex justify-content-center">
                    <div>
                        {responses.map((response, index) => (
                            <ResponseCard key={index}
                                          response={response}
                                          allResponsesIn={allResponsesIn}
                                          showButtons={false}/>
                        ))}
                    </div>
                </div>
            )}

            <div className="d-flex justify-content-start">
                <Card className="p-4 text-center">
                    <Card.Title>{phase}</Card.Title>
                    <Card.Body>
                        <Card.Text>
                            Current Emcee: {currentPlayer.name}
                            <br />
                            Current Round: {currentRound + 1}
                            <br />
                            Current Guess: {currentGuess}
                            {currentQuestion && (
                                <>
                                    <br />
                                    Current Question: {currentQuestion}
                                </>
                            )}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default MainScreen;
