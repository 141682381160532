import { useState, useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';

import {arrayUnion, doc, onSnapshot, updateDoc} from 'firebase/firestore';

import WhoWroteThis from "./WhoWroteThis/WhoWroteThis";

import { v4 as uuidv4 } from 'uuid';

import {getSubPhase} from "../utils";
const Phase1Chump = ({ gameID, playerId, app, db }) => {
    const [mc, setMC] = useState('');
    const [mcName, setMCName] = useState('');
    const [players, setPlayers] = useState([]);

    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState('');
    const [playerIDs, setPlayerIDs] = useState([]);
    const [showCard, setShowCard] = useState(true);
    const [isMyTurn, setIsMyTurn] = useState(false);

    const [subPhase, setSubPhase] = useState("not loaded");

    useEffect(() => {
        const gameRef = doc(db, 'games', gameID);

        const unsubscribe = onSnapshot(gameRef, (docSnapshot) => {
            console.log('Phase1Chump.jsx: read')
            if (docSnapshot.exists()) {
                const gameData = docSnapshot.data();
                setSubPhase(getSubPhase(gameData.gameState));

                if (gameData.gameState.mc !== mc) {
                    setMC(gameData.gameState.mc);
                    setQuestion(undefined);
                    setShowCard(true);
                }
                setMC(gameData.gameState.mc);
                setMCName(gameData.players[gameData.gameState.mc].name);
                setPlayers(gameData.players);
                setPlayerIDs(gameData.playerIDs);
                setQuestion(gameData.gameState.currentQuestion);

                const whoseGuess = gameData.gameState.whoseGuess || 0;
                const playerIndex = gameData.playerIDs.findIndex((id) => id === playerId);

                if (playerIndex === whoseGuess) {
                    setIsMyTurn(true);
                } else {
                    setIsMyTurn(false);
                }
                const playerResponse = gameData.gameState.currentResponses.find((response) => response.playerID === playerId);
                if (playerResponse) {
                    setShowCard(false);
                }
            } else {
                console.log(`No game found with ID ${gameID}`);
            }
        });

        return () => unsubscribe();
    }, [db, gameID, question, playerId, mc]);

    const handleResponseSubmit = async (e) => {
        e.preventDefault();

        // Check if the response is not empty
        if (response.trim() === '') {
            // You can display an error message or handle it in your preferred way
            console.log('Response cannot be blank');
            return; // Exit the function without submitting
        }

        try {
            const gameRef = doc(db, 'games', gameID);
            const i = playerIDs.indexOf(playerId);

            const data = {
                playerID: playerId,
                playerName: players[i].name,
                response: response,
                id: uuidv4(), // Generate a new UUID for the response
            };

            await updateDoc(gameRef, {
                "gameState.currentResponses": arrayUnion(data),
            });

            setShowCard(false);
            setResponse("");
        } catch (error) {
            console.error('Error adding response:', error);
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{
                minHeight: '100vh',
                backgroundImage: 'url("/img/chumps04.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',}}>
            {showCard && (

            <Card style={{ width: '50%', opacity: 0.9 }}>
                <Card.Body>
                    <Card.Title>Phase 1</Card.Title>
                    <Card.Text>
                        Current Emcee: {mcName ? mcName : 'Waiting for Emcee...' }
                        <br />
                        {question ? `Question: ${question}` : 'Waiting for question...'}
                    </Card.Text>
                    {(subPhase === "awaitingResponses") &&
                        <Form onSubmit={handleResponseSubmit}>
                            <Form.Group>
                                <Form.Label>Your response:</Form.Label>
                                <Form.Control type="text" value={response} onChange={(e) => setResponse(e.target.value)} />
                            </Form.Group>
                            <br />
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    }
                </Card.Body>
            </Card>
                )}
            {isMyTurn && !showCard && (
                <WhoWroteThis gameID={gameID}
                              playerId={playerId}
                              isMC={false}
                              app={app}
                              db={db}
                              subphase={subPhase}/>
            )}
        </div>
    );
};

export default Phase1Chump;
