import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { collection, query, where, getDocs } from 'firebase/firestore';


import { v4 as uuidv4 } from 'uuid';

import { useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

const Join = ({app, db}) => {
    const { gameCode } = useParams();
    const navigate = useNavigate();
    const [game, setGame] = useState(null);
    const [gameId, setGameId] = useState(null);
    const [playerName, setPlayerName] = useState('');
    const [playerEmail, setPlayerEmail] = useState('');
    const [phase, setPhase] = useState(null);

    useEffect(() => {
        console.log(`Looking for game ${gameCode}`);

        const gamesCollection = collection(db, 'games');
        const q = query(gamesCollection, where('code', '==', Number(gameCode)));

        getDocs(q)
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // Set the game state to the retrieved document data
                    setGame(doc.data());
                    setGameId(doc.id);
                    setPhase(doc.data().gameState.phase);
                });
            })
            .catch((error) => {
                console.error('Error retrieving game:', error);
            });
    }, [gameCode, db]);

    const handleAddPlayer = async () => {


        // Create a new player object with the entered name and email
        const newPlayer = {
            name: playerName,
            email: playerEmail,
            id: uuidv4(),
            bestAnswerPoints: 0,
            correctGuessPoints: 0,
        };

        const gameDocRef = doc(db, 'games', gameId);
        await updateDoc(gameDocRef, {
            playerIDs: arrayUnion(newPlayer.id),
            players: arrayUnion(newPlayer),
        });

        console.log(`Player added to game ${gameCode}`);

        // Redirect the user back to the game page
        navigate(`/play/${newPlayer.id}`);
    };

    const handleNameChange = (event) => {
        setPlayerName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setPlayerEmail(event.target.value);
    };

    return phase === 'open' ? (
        <Container>
            {game ? (
                <>
                    <h1>Join Game {gameCode}</h1>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" onChange={handleNameChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" onChange={handleEmailChange} />
                        </Form.Group>

                        <Button variant="primary" onClick={handleAddPlayer} style={{ marginTop: '20px' }}>
                            Add Player
                        </Button>
                    </Form>
                </>
            ) : (
                <p>Loading game...</p>
            )}
        </Container>
    ) : (
        <header
            style={{
                backgroundImage: `url('/img/game-closed.png')`,
                minHeight: '1000px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundSize: 'cover',
                backgroundPosition: 'center center'
            }}
        >
            <h1 style={{
                position: 'absolute',
                left: '0',
                top: '40',
                color: 'limegreen',
                fontFamily: 'Courier, monospace',
                animation: 'blinker 1s infinite',
            }}>game closed</h1>
        </header>
    );;
};

export default Join;
