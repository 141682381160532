import React, {useState} from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import {getRandomRulesImage} from "../utils";



const Rules = () => {

    const [bgImage] = useState(`url('${process.env.PUBLIC_URL}/img/${getRandomRulesImage()}')`);

    return (
        <div
            style={{
                backgroundImage: bgImage,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '100vh',
                display: 'flex',            // Add display flex
                alignItems: 'center',       // Vertically center the container
                justifyContent: 'center',
            }}
        >
            <Container
                style={{
                    backgroundColor: 'white',
                    opacity: 0.8,
                    padding: '2rem',
                    borderRadius: '1rem',
                    boxShadow: '0 0 1rem #aaa',
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 4rem)',
                }}
            >
                <Row>
                    <Col>
                        <h1>Rules</h1>
                            <p>
                                You’re cordially invited to the Catalina Wine Mixer, an open source par-tay where we all get
                                a turn at being the party Emcee.  Gameplay begins with all players being assigned a random
                                player order, which remains consistent throughout all gameplay in Phases I and II.
                            </p>
                        <h2>Phase I: The Wine Mixer</h2>
                            <p>
                                Players take turns playing the role of Emcee, with the player who has the number one spot in
                                the order going first.   As Emcee, you’re in charge or running the par-tay and have four
                                steps you’re responsible for in each turn:
                            </p>
                            <h3>Emcee Steps</h3>
                            <ul>
                                <li>1. Come up with a prompt for all of the Players, including the Emcee, to write a
                                    response to.  The prompt can be anything from “What is Catalina’s town moto?” to
                                    “Why does Waldo keep hiding?”
                                </li>
                                <li>2. Once all answers have been submitted, Emcee will reveal each answer one-by-one…
                                    flair, pageantry, and hyperbole are expressly encouraged.
                                </li>
                                <li>3. Officiate the Guessing Round of the turn.</li>
                                <li>4. End the turn.</li>
                            </ul>
                        <h3>Guessing Round</h3>
                            <p>
                                After the Emcee has revealed all of the answers, the second player in the order will
                                take a turn guessing who wrote each answer.  For each correct guess, the Emcee will give
                                that player one point.  The player will keep guessing until they guess incorrectly.
                                Once the player guesses incorrectly, the next player in the order will get a turn to
                                guess who wrote which answer.  The turn ends when all answers have been guessed
                                correctly or when each player, other than the Emcee, has had a chance to guess.  If, at
                                the end of the turn there are answers whose authors have not been guessed, the Emcee
                                will reveal those authors at the end of the turn.
                            </p>
                        <h3>Ending Turn</h3>
                            <p>
                                When the Guessing Round is complete, the Emcee will award a bonus point to the Emcee’s
                                favorite answer.  After awarding the bonus point, the Emcee will then choose to “Advance
                                to Phase II” or to  “End Turn.”  End Turn will pass Emcee responsibilities to the next
                                player in the order and the next turn will begin.  If the Emcee elects to Advance to
                                Phase II, the next player in the order will still become the next Emcee but, instead,
                                Phase II rules will apply to that next turn.
                            </p>
                            <p><mark>
                                Gameplay in Phase II is ~4 - 5X faster than in Phase I, so - if you play Phase I for one
                                hour - you will likely be able to play Phase II for ~ 15 minutes before running out of
                                answers.
                            </mark></p>
                        <h2>Phase II: The After Party</h2>
                            <p>
                                You made the mistake of going to a 4AM bar after the Catalina Wine Mixer.  It has been a
                                long day and you don’t have the energy to think of new ideas, but - lucky for you - you
                                were a chatty Kathy at the par-tay and have a lot of good answers from the Catalina Wine
                                Mixer to drive your late night conversations.  At the beginning of Phase II, all players
                                are randomly assigned five answers from the Phase I pool.  Players will use one answer
                                in each turn of Phase II.  At the end of each turn, each player will receive a random
                                replacement answer from the Phase I pool until all the Phase I answers have been used,
                                which marks the end of the game.  Like Phase I, players take turns playing the role of
                                Emcee.  All Emcee responsibilities remain the same, but the Guessing Round is replaced
                                with a Best Answer Round in Phase II
                            </p>
                        <h3>Emcee Steps.</h3>
                        <ol>
                            <li>1. Come up with a prompt for all of the Players, including the Emcee, to submit a
                                response to.  The prompt can be anything from “Things you shouldn’t do with chocolate?”
                                to “Why does Waldo keep hiding?”  Instead of writing answers, in Phase II the players
                                will utilize one of their five Phase I answers that they’ve randomly been assigned.
                            </li>
                            <li>2. Once all answers have been submitted, Emcee will reveal each answer one-by-one…
                                flair, pageantry, and hyperbole are expressly encouraged.
                            </li>
                            <li>3. Officiate the Best Answer Round of the turn.</li>
                            <li>4. End the Turn.</li>
                        </ol>
                        <h3>Best Answer Round</h3>
                        <p>
                            After the Emcee has revealed all of the answers submitted, the Emcee will choose which
                            answer the Emcee likes best and give three points to the player.  The system will also
                            automatically assign three points to whoever wrote the winning answer back in Phase I.
                        </p>
                        <h3>Ending Turn</h3>
                        <p>
                            When the Best Answer Round is complete, the Emee will end the turn.
                        </p>
                        <h2>Winning</h2>
                        <p>
                            Gameplay ends when all of the answers submitted at the Catalina Wine Mixer (Phase I) have
                            been used at The Afterparty (Phase II).  As they announce “last call” at The Afterparty, you
                            and your friends decide to immortalize three heroes from your epic soiree:
                        </p>
                        <ol>
                            <li><b>The Life of the Party -</b>&nbsp; person with the most points</li>
                            <li><b>The Most Interesting Person in the Room -</b>&nbsp; person with the most
                                points.</li>
                            <li><b>The Sommelier -</b>&nbsp;person with the most Phase I bonus points</li>
                            <li><b>The Inebriated Nostradameus -</b>&nbsp;person with the most Phase I answers chosen
                                as Best Answer in Phase II</li>
                        </ol>


                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Rules;
