import React, { useState } from 'react';
import { Button, Card, Form, Container, Row, Col } from 'react-bootstrap';


import { v4 as uuidv4 } from 'uuid';


const Create = (gameId, app, db) => {

    const [email, setEmail] = useState('');
    const [emails, setEmails] = useState([]);

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleAddEmail = () => {
        const playerId = uuidv4(); // Generate a new UUID for the player
        setEmails([...emails, { playerId, email }]);
        setEmail('');

    };

    const handleCreate = () => {
        // Your code to add emails to the Firestore collection...
    };

    return (
        <div style={{ margin: '20px' }}>
            <Container>
                <h2>Create New Game</h2>
                <Row>
                    <Col md={8}>
                        <Form>
                            <Form.Group controlId="email">
                                <Form.Label>Add Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={handleChange}
                                    placeholder="Enter email"
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={4}>
                        <Button
                                variant="outline-primary"
                                size="lg"
                                onClick={handleAddEmail}
                                block="true">
                            Add
                        </Button>
                    </Col>
                </Row>
                <br />
                <Row>
                    {emails.map(({ playerId, email }) => (
                        <Col md={4} key={playerId}>
                            <Card border="dark" style={{ margin: '10px' }}>
                                <Card.Body>
                                    <Card.Title>{email}</Card.Title>
                                    <Card.Text>ID: {playerId}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <br />
                <Button variant="success" onClick={handleCreate} block="true">
                    Create
                </Button>
            </Container>
        </div>
    );
};


export default Create;
