import React, {useState, useEffect} from "react";
import {Card} from "react-bootstrap"

const EndGame = ({ gameData, playerId }) => {
    const [isLoser, setIsLoser] = useState(false);
    const [isStylePoints, setIsStylePoints] = useState(false);
    const [isRealPoints, setIsRealPoints] = useState(false);

    const players = gameData.players;
    // function getPlayerWinners(players, playerId) {
    useEffect( () => {

        // Find the player with the specified playerId
        const currentPlayer = players.find(player => player.id === playerId);

        if (!currentPlayer) {
            // Player with the specified ID not found
            return {
                bestAnswerWinner: false,
                correctGuessWinner: false
            };
        }

        // Calculate the maximum points in each category
        const maxBestAnswerPoints = Math.max(
            ...players.map(player => player.bestAnswerPoints)
        );
        const maxCorrectGuessPoints = Math.max(
            ...players.map(player => player.correctGuessPoints)
        );

        // Determine if the currentPlayer has the most points in each category
        const styleWinner = currentPlayer.bestAnswerPoints === maxBestAnswerPoints;
        const realWinner = currentPlayer.correctGuessPoints === maxCorrectGuessPoints;

        if (styleWinner) {
            setIsStylePoints(true);
        }
        if (realWinner) {
            setIsRealPoints(true);
        }
        if ((!styleWinner) && (!realWinner)) {
            setIsLoser(true);
        }


    }, [players, playerId])

    const pointWinnerImages = [
        'url("/img/endgame-01.png")',
        'url("/img/endgame-02.png")',
        'url("/img/endgame-03.png")'
    ]

    const losingPhrases = [
        "To lose the Catalina Wine Mixer will be an awfully big adventure. - J.M. Barrie",
        "Losing the Catalina Wine Mixer is the last chapter in time, but the first chapter in eternity. - Unknown",
        "I'm not afraid of losing the Catalina Wine Mixer; I just don't want to be there when it happens. - Woody Allen",
        "The fear of losing the Catalina Wine Mixer follows from the fear of life. A man who lives fully is prepared to lose the Catalina Wine Mixer at any time. - Mark Twain",
        "Losing the Catalina Wine Mixer is nature's way of telling you to slow down. - Dick Sharples",
        "Life is for the living. Losing the Catalina Wine Mixer is for the 'losing' dead. Let life be like music. And losing the Catalina Wine Mixer a note unsaid. - Langston Hughes",
        "I do not fear losing the Catalina Wine Mixer. I had been 'Catalina Wine Mixer-less' for billions and billions of years before I was born, and had not suffered the slightest inconvenience from it. - Mark Twain",
        "The goal of all life is losing the Catalina Wine Mixer. - Sigmund Freud",
        "Losing the Catalina Wine Mixer is not the greatest loss in life. The greatest loss is what dies inside us while we live. - Norman Cousins",
        "The mystery of life and losing the Catalina Wine Mixer cannot be unraveled completely, and that's what makes the journey so exciting. - Amelia Earhart",
    ]

    const losingImages = [
        'url("/img/lost_01.png")',
        'url("/img/lost_02.png")'
    ]
    return (
        <div>
            {isRealPoints && (
                <div className="d-flex justify-content-center align-items-center" style={{
                    minHeight: '100vh',
                    backgroundImage: pointWinnerImages[Math.floor(Math.random() * pointWinnerImages.length)],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',}}>
                    <Card style={{ width: '50%', opacity: 0.9 }}>
                        <Card.Body>
                            <Card.Title>You "Win"</Card.Title>
                            <Card.Text>
                                A Pyrrhic victory (/ˈpɪrɪk/ ⓘ PIRR-ik) is a victory that inflicts such a devastating toll on the victor that it is tantamount to defeat. Such a victory negates any true sense of achievement or damages long-term progress.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            )}
            {isStylePoints && (
                <div className="d-flex justify-content-center align-items-center" style={{
                    minHeight: '100vh',
                    backgroundImage: 'url("/img/style01.png")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',}}>
                    <Card style={{ width: '50%', opacity: 0.9 }}>
                        <Card.Body>
                            <Card.Title>Most Style Points</Card.Title>
                            <Card.Text>
                                ... and looking good while losing is the true mark of a champion. -Vince Lombardi
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

            )}
            {(isLoser) && (
                <div className="d-flex justify-content-center align-items-center" style={{
                    minHeight: '100vh',
                    backgroundImage: losingImages[Math.floor(Math.random() * losingImages.length)],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',}}>
                    <Card style={{ width: '50%', opacity: 0.9 }}>
                        <Card.Body>
                            <Card.Title>You Lose</Card.Title>
                            <Card.Text>
                                {[losingPhrases[Math.floor(Math.random() * losingPhrases.length)]]}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            )}
        </div>
    );
}

export default EndGame;
