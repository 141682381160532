import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {Button,
    Card,
    Container,
    Dropdown,
    ListGroup} from "react-bootstrap";
import {doc,
    updateDoc,
    onSnapshot} from "firebase/firestore";

import {getSubPhase, resetRound} from "../../utils";

const Dev = ({app, db}) => {
    const { gameid } = useParams();

    const [state, setState] = useState({});
    const [players, setPlayers] = useState([]);
    const [whoseGuess, setWhoseGuess] = useState(undefined);
    const [mc, setMc] = useState(undefined);
    const [subPhase, setSubPhase] = useState("not loaded");
    const [allResponsesIn, setAllResponsesIn] = useState("not loaded");
    const [nRespsonses, setNResponses] = useState(0);
    const [roundsPerPhase, setRoundsPerPhase] = useState(0);

    const [selectedRound, setSelectedRound] = useState(0);
    const [selectedPhase, setSelectedPhase] = useState("not loaded");


    const phaseOptions = ["phase1", "halftime", "phase2", "endgame"];

    // Create an array of round options from 1 to roundsPerPhase
    const roundOptions = [];
    for (let i = 1; i <= roundsPerPhase; i++) {
        roundOptions.push(i);
    }

    // Handle the selection change
    const handleRoundSelect = (selectedValue) => {
        setSelectedRound(selectedValue);
        updateDoc(doc(db, 'games', gameid),
            {"gameState.round": selectedValue - 1}
        ).then(r => console.log("Round updated"));
    };

    const handlePhaseSelect = (selectedValue) => {
        setSelectedPhase(selectedValue);
        updateDoc(doc(db, 'games', gameid),
            {"gameState.phase": selectedValue}
        ).then(r => console.log("Phase updated"));
    };
    useEffect( () => {
        // data is loaded, lets start setting things
        if (state && state.whoseGuess !== undefined && players && players.length > state.whoseGuess) {
            setWhoseGuess(players[state.whoseGuess].name || "");
            setMc(players[state.mc].name || "");
            if (state.allResponsesIn === undefined) {
                setAllResponsesIn("not loaded");
            } else {
                setAllResponsesIn(state.allResponsesIn.toString() || "");
            }
            if (state.currentResponses) {
                setNResponses(state.currentResponses.length);
            }

        }

    }, [state, players]);

    useEffect(() => {
        const gameRef = doc(db, 'games', gameid);

        const unsubscribe = onSnapshot(gameRef, (docSnapshot) => {
            console.log('Dev.jsx: read')
            if (docSnapshot.exists()) {
                const gameData = docSnapshot.data();
                setState(gameData.gameState);
                setPlayers(gameData.players);
                setRoundsPerPhase(gameData.phase1_rounds);
                setSelectedRound(gameData.gameState.round + 1);
                setSelectedPhase(gameData.gameState.phase);
                // setWhoseGuess(gameData.gameState.whoseGuess || "");

                setSubPhase(getSubPhase(gameData.gameState));

            }
        });

        return unsubscribe;
    }, [gameid, db]);

    const handleResetRound = () => {
        const gameRef = doc(db, 'games', gameid);
        resetRound(gameRef,
            state.phase,
            state.round,
            0,
            0);
    }
    return (
        <div
            style={{
                backgroundImage: 'url("../img/dev-01.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div style={{ background: 'rgba(255, 255, 255, 0.8)', padding: '20px' }}>
        <Container>
                <Card>
                    <Card.Header>
                        <h1>Game State Information</h1>
                    </Card.Header>
                    <Card.Body>
                        <ListGroup variant="flush">
                            <ListGroup.Item>Phase:
                                <Dropdown onSelect={handlePhaseSelect}>
                                    <Dropdown.Toggle variant="light">
                                        {selectedPhase}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {phaseOptions.map((option) => (
                                            <Dropdown.Item
                                                key={option}
                                                eventKey={option}
                                            >
                                                {option}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ListGroup.Item>
                            <ListGroup.Item>Sub Phase: {subPhase}</ListGroup.Item>
                            <ListGroup.Item>All Responses In: {allResponsesIn}</ListGroup.Item>
                            <ListGroup.Item>Responses So Far: {nRespsonses}</ListGroup.Item>
                            <ListGroup.Item>
                                Round:
                                <Dropdown onSelect={handleRoundSelect}>
                                    <Dropdown.Toggle variant="light">
                                        {selectedRound}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {roundOptions.map((option) => (
                                            <Dropdown.Item
                                                key={option}
                                                eventKey={option}
                                            >
                                                {option}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ListGroup.Item>
                            <ListGroup.Item>Current Question: "{state.currentQuestion}"</ListGroup.Item>
                            <ListGroup.Item>Whose Guess: {whoseGuess}</ListGroup.Item>
                            <ListGroup.Item>MC: {mc}</ListGroup.Item>
                        </ListGroup>
                        <Button onClick={handleResetRound}>Reset Round</Button>
                    </Card.Body>
                </Card>
            </Container>
            </div></div>
    );
};

export default Dev;
