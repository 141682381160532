import { updateDoc } from 'firebase/firestore';

export function advanceNextRound(gameRef,
                                 gameData) {

    const gamePhases = ['open', 'phase1', 'halftime', 'phase2', 'endgame'];
    const players = gameData.players;
    const currentMC = gameData.gameState.mc;
    const currentRound = gameData.gameState.round;
    const currentPhase = gameData.gameState.phase;
    const phaseOneRounds = gameData.phase1_rounds;

    let newMC = (currentMC + 1) % players.length;
    let updatedRound = currentRound;
    let updatedPhase = currentPhase;

    if (currentMC + 1 >= players.length) {
        newMC = 0;
        updatedRound = currentRound + 1;

        if (updatedRound > phaseOneRounds - 1) {
            const currentPhaseIndex = gamePhases.indexOf(currentPhase);
            const nextPhaseIndex = (currentPhaseIndex + 1) % gamePhases.length;
            updatedPhase = gamePhases[nextPhaseIndex];
        }
    }

    const gameState = {
        allResponsesIn: false,
        currentQuestion: '',
        currentResponses: [],
        mc: newMC,
        phase: updatedPhase,
        round: updatedRound,
        whoseGuess: newMC,
    }

    console.log("advanceNextRound", gameState, gameRef);
    updateDoc(gameRef, { gameState: gameState });

}
export function resetRound(gameRef,
                           phase,
                           round,
                           mc,
                           whoseGuess) {
    const gameState = {
        allResponsesIn: false,
        currentQuestion: '',
        currentResponses: [],
        mc: mc,
        mcHasGuessed: false,
        phase: phase,
        round: round,
        whoseGuess: whoseGuess,
        halftime_video_watched: phase !== "phase1" && phase !== "halftime",
    }
    updateDoc(gameRef, { gameState: gameState });
}


export function getSubPhase(gameState) {

    let hasBestResponse = false;
    if (gameState.phase === "phase1") {
        hasBestResponse = gameState.currentResponses.some((response) => response.bestAnswer === true);
    }

    const allResponsesCorrectlyGuessed = gameState.currentResponses.every((response) => response.correctlyGuessed === true);

    if (gameState && gameState.currentQuestion !== undefined && gameState.currentQuestion === "") {
        return "composeQuestion";
    } else if ((gameState.currentResponses.length > 0) && allResponsesCorrectlyGuessed) {
        return "roundOver";
    } else if ((gameState.phase === "phase1") && (hasBestResponse)) {
            return "chooseWhoWroteWhat";
    } else if (gameState.allResponsesIn) {
            return "chooseBestResponse";
    } else if ( gameState && gameState.currentQuestion !== undefined && gameState.currentQuestion !== "") {
        return "awaitingResponses";
    } else {
            return "unknown"; // You can return null or any other value you prefer
            // when the condition is not met
    }

}

const openerImages = [
    "catalina01.png",
    "catalina02.png",
    "catalina03.png",
    "catalina04.png",
    "catalina05.png",
    "catalina06.png",
    "catalina07.png",
    "catalina08.png",

    ];
export function getRandomOpenerImage() {
    return openerImages[Math.floor(Math.random() * openerImages.length)];
}

const halftimeImages = [
    "halftime01.png",
    "halftime02.png",
    "halftime03.png",
    "halftime04.png",
    "halftime05.png",
    "halftime06.png",
]

export function getRandomHalftimeImage() {
    return halftimeImages[Math.floor(Math.random() * halftimeImages.length)];
}

const halftimeVideos = [
    "https://www.youtube.com/watch?v=dQw4w9WgXcQ", // Rick Roll
    "https://www.youtube.com/watch?v=nDHJ0aMISiA", // Tecmo Bowl
    "https://www.youtube.com/watch?v=ubAaDlJUmxM", // e-elk - cuck
]

export function getRandomHalftimeVideo() {
    return halftimeVideos[Math.floor(Math.random() * halftimeVideos.length)];
}

const rulesImages = [
    "rules01.png",
    "rules02.png",
    "rules03.png",
]

export function getRandomRulesImage() {
    return rulesImages[Math.floor(Math.random() * rulesImages.length)];
}

const allImages = [
    "catalina-hold-screen1.png",
    "catalina07.png",
    "dev-01.png",
    "halftime02.png",
    "mc1.png",
    "catalina01.png",
    "catalina08.png",
    "endgame-01.png",
    "halftime03.png",
    "mc2.png",
    "catalina02.png",
    "chumps01.png",
    "endgame-02.png",
    "halftime04.png",
    "phase1.png",
    "catalina03.png",
    "chumps02.jpg",
    "endgame-03.png",
    "halftime05.png",
    "rules01.png",
    "catalina04.png",
    "chumps03.jpg",
    "endgame_predictable_01.png",
    "halftime06.png",
    "rules02.png",
    "catalina05.png",
    "chumps04.png",
    "lost_01.png",
    "rules03.png",
    "catalina06.png",
    "chumps05.png",
    "halftime01.png",
    "lost_02.png",
    "style01.png"

]
export function getRandomImage() {
    return allImages[Math.floor(Math.random() * allImages.length)];
}