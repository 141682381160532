import React, {useState} from 'react';

import {Container,
    Row,
    Col,
    Card,
    Form} from 'react-bootstrap';
import {doc, updateDoc} from "firebase/firestore";

// import MidiPlayer from 'react-midi-player';


const OpenGame =  (props) => {
    const { gameCode, players, gameId, db } = props;
    const [rounds, setRounds] = useState(5);
    const [devMode, setDevMode] = useState(false); // Add devMode state

    const handleRoundsChange = (event) => {
        const selectedRounds = parseInt(event.target.value);
        setRounds(selectedRounds);

        const gameRef = doc(db, 'games', gameId);
        gameRef.update({ phase1_rounds: selectedRounds }).catch((error) => {
            console.error('Error updating game:', error);
        });

    };

    const handleDevModeToggle = () => {
        setDevMode(!devMode);
        // Update the associated document with devMode value

        const gameRef = doc(db, 'games', gameId);
        updateDoc(gameRef, { devMode: !devMode })
    };

    return (
        <Container>
            <Row className="justify-content-between">
                <Col className="text-right mt-4">
                    <Card bg="danger"
                          text="light">
                        <Card.Body>
                            <Card.Title>Game Code: {gameCode}</Card.Title>
                            <Card.Text>To Join Use Another Device and Surf to www.catalina-wine-mixer.com/join/{gameCode}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="text-right mt-4">
                    <Card bg="warning">
                        <Card.Body>
                            <Form.Group controlId="rounds">
                                <Form.Label>Select number of rounds to play in Phase 1:</Form.Label>
                                <Form.Control as="select" value={rounds} onChange={handleRoundsChange}>
                                    <option value={1}>1</option>
                                    <option value={3}>3</option>
                                    <option value={5}>5</option>
                                    <option value={7}>7</option>
                                </Form.Control>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-between">
                <Col className="text-right mt-4">
                    <Card bg="info">
                        <Card.Body>
                            <Form.Group controlId="devModeToggle">
                                <Form.Check
                                    type="switch"
                                    id="devModeSwitch"
                                    label="Dev Mode"
                                    checked={devMode}
                                    onChange={handleDevModeToggle}
                                />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <h1>Players</h1>
                <Col>
                    {players.map((player) => (
                        <Card key={player.name} className="mb-3">
                            <Card.Body>
                                <Card.Title>{player.name}</Card.Title>
                                <Card.Text>{player.email}</Card.Text>
                            </Card.Body>
                        </Card>
                    ))}
                </Col>
            </Row>
        {/*<MidiPlayer data={midiData} autoplay="true" />*/}
        </Container>
    );
}

export default OpenGame;
