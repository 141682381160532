import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/Home.jsx';
import Game from './components/Game.jsx';
import Join from './components/Join.jsx';
import Play from './components/Play.jsx';
import Rules from './components/Rules.jsx';
import Create from './components/Create.jsx';
import Dev from './components/Dev/Dev.jsx';

import 'bootstrap/dist/css/bootstrap.min.css';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';



const firebaseConfig = {
    apiKey: "AIzaSyAZz0uhIoS3OQ3Xdj9NtowQ05d-pYNb4MA",
    authDomain: "catalina-wine-mixer.firebaseapp.com",
    projectId: "catalina-wine-mixer",
    storageBucket: "catalina-wine-mixer.appspot.com",
    messagingSenderId: "201151841244",
    appId: "1:201151841244:web:6e0852ec5596d4e7fcc519",
    measurementId: "G-29WQG0MN2C"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


function App() {


    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<Home app={app} db={db} />} />
                    <Route
                        path="/game/:gameid"
                        element={ <Game app={app} db={db} /> }
                    />
                    <Route
                        path="/join/:gameCode"
                        element={ <Join app={app} db={db} /> }
                    />
                    <Route
                        path="/create"
                        element={<Create app={app} db={db} /> }
                    />
                    <Route
                        path="/play/:playerId"
                        element={ <Play app={app} db={db} /> }
                    />
                    <Route path="/rules" element={<Rules />} />
                    <Route path="/dev/:gameid" element={ <Dev app={app} db={db} /> } />

                </Routes>
            </div>
        </Router>
    );
}

export default App;
