import { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';

import { arrayUnion, arrayRemove, doc, updateDoc } from 'firebase/firestore';

const Phase2Chump = ({ gameID, playerId, app, db, gameData }) => {
    const [mc, setMC] = useState('');
    const [mcName, setMCName] = useState('');

    const [question, setQuestion] = useState('');
    const [playerIDs, setPlayerIDs] = useState([]);
    const [showCard, setShowCard] = useState(true);
    const [myCards, setMyCards] = useState([]);

    useEffect(() => {
        if (gameData.gameState.mc !== mc && true) {
            setMC(gameData.gameState.mc);
            setQuestion(undefined);
            setShowCard(true);
        }
        setMC(gameData.gameState.mc);
        setMCName(gameData.players[gameData.gameState.mc].name);

        setPlayerIDs(gameData.playerIDs);

        setQuestion(gameData.gameState.currentQuestion);

        const playerIndex = gameData.playerIDs.findIndex((id) => id === playerId);


        if (gameData.phase2cards[playerIndex].length > 0) {
            setMyCards(gameData.phase2cards[playerIndex].slice(0, 5));
        } else {
            console.log('End game, but we should have caught it before now');
            // gameRef.update({ gameState: { phase: 'endgame' } });
        }

        const playerResponse = gameData.gameState.currentResponses.find(
            (response) => response.playedByInPhase2 === playerId
        );
        if (playerResponse) {
            setShowCard(false);
        }

    }, [mc, gameData, playerId])


    const handleResponseSubmit = async (card) => {
        try {
            const gameRef = doc(db, 'games', gameID);
            const playerIndex = playerIDs.findIndex((id) => id === playerId);

            const updateObj = {};

            updateObj[`gameState.currentResponses`] = arrayUnion({ ...card, playedByInPhase2: playerId });
            updateObj[`phase2cards.${playerIndex}`] = arrayRemove(card);

            await updateDoc(gameRef, updateObj);
            setShowCard(false);
        } catch (error) {
            console.error('Error updating response:', error);
        }
    };

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                minHeight: '100vh',
                backgroundImage: 'url("/img/chumps01.png")',
                backgroundSize: 'cover',
            }}
        >
            {showCard && (
                <Card style={{ width: '50%', opacity: 0.9 }}>
                    <Card.Body>
                        <Card.Title>Phase 2</Card.Title>
                        <Card.Text>
                            Current Emcee: {mcName ? mcName : 'Waiting for Emcee...'}
                            <br />
                            {question ? `Question: ${question}` : 'Waiting for question...'}
                        </Card.Text>
                        {question &&
                            myCards.map((card) => (
                                <Card key={card.id} style={{ marginBottom: '20px' }}>
                                    <Card.Body>
                                        <Card.Text>{card.response}</Card.Text>
                                        <Button variant="primary" onClick={() => handleResponseSubmit(card)}>
                                            Play This Card
                                        </Button>
                                    </Card.Body>
                                </Card>
                            ))}
                    </Card.Body>
                </Card>
            )}
        </div>
    );
};

export default Phase2Chump;
