import React from 'react';
import ReactPlayer from 'react-player/youtube'

import {doc, updateDoc} from "firebase/firestore";
import {getRandomHalftimeVideo} from "../utils";
const Halftime = ({gameId, app, db}) => {

    const videoUrl = getRandomHalftimeVideo();

    const handleVideoEnd = async () => {
        try {
            const gameRef = doc(db, 'games', gameId);
            await updateDoc(gameRef,
                { "gameState.halftime_video_watched": true });
        } catch (error) {
            console.error('Error updating game:', error);
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'black',
            width: '100vw',
            height: '100vh',
        }}>
            <ReactPlayer
                        url={videoUrl}
                        height="720px"
                        width="1280px"
                        playing={true}
                        playsinline={true}
                        onEnded={handleVideoEnd}

                    />
        </div>
    );
};

export default Halftime;

