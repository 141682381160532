// Header.jsx
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

import OpenGame from './OpenGame';
import MainScreen from './MainScreen/MainScreen';

import Button from 'react-bootstrap/Button';
import Halftime from "./Halftime";
import {doc, onSnapshot, updateDoc} from "firebase/firestore";




// const database = firebase.database();
const Game = ({app, db}) => {
    const { gameid } = useParams();
    const [gameCode, setGameCode] = useState('');
    const [players, setPlayers] = useState([]);
    const [phase, setPhase] = useState('');


    useEffect(() => {
        const gameRef = doc(db, 'games', gameid);

        const unsubscribe = onSnapshot(gameRef, (docSnapshot) => {
            console.log('Game.jsx: read')
            if (docSnapshot.exists()) {
                const gameData = docSnapshot.data();
                setGameCode(gameData.code);
                setPlayers(gameData.players);
                setPhase(gameData.gameState.phase);
                if ((gameData.gameState.phase === "halftime") &&
                    (gameData.gameState.halftime_video_watched)){
                    // Moving to phase 2
                    console.log("Phase2");

                    const responseArchive = gameData.responseArchive;
                    const playerIDs = gameData.playerIDs;

                    const n = playerIDs.length;

                    // Shuffle the responseArchive array randomly
                    const shuffledResponses = shuffle(responseArchive);
                    // Split the shuffled array into n new arrays
                    const responsesPerPlayer = Math.floor(shuffledResponses.length / n);
                    const responseArrays = [];
                    let startIndex = 0;
                    let endIndex = responsesPerPlayer;
                    for (let i = 0; i < n; i++) {
                        responseArrays.push(shuffledResponses.slice(startIndex, endIndex));
                        startIndex = endIndex;
                        endIndex += responsesPerPlayer;
                    }


                    let phase2cards = {};

                    for (let i = 0; i < n; i++) {
                        phase2cards[i] = responseArrays[i];
                    }

                    const newGameState = {
                        allResponsesIn: false,
                        currentQuestion: '',
                        currentResponses: [],
                        mc: 0,
                        mcHasGuessed: false,
                        phase: "phase2",
                        round: 0,
                        whoseGuess: 0,
                        halftime_video_watched: true,
                    }

                    updateDoc( gameRef, {
                        phase2cards: phase2cards,
                        gameState: newGameState,
                    })

                }
                // If it's the last round of Phase 1, move to halftime
                if (gameData.gameState.phase === "phase1" &&
                    gameData.round === gameData.phase1_rounds - 1) {
                    updateDoc(gameRef, {"gameState.phase": "halftime"});
                } else if (gameData.gameState.phase === "endgame") {
                    updateDoc(gameRef, {"gameState.phase": "endgame"});
                    console.log("Game has ended");
                }
            } else {
                console.log(`No game found with ID ${gameid}`);
            }
        });

        return unsubscribe;





        // Define a shuffle function to randomly shuffle an array
        function shuffle(array) {
            const shuffledArray = array.slice();
            for (let i = shuffledArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
            }
            return shuffledArray;
        }
    }, [gameid,db]);


    const handleLockGame = async () => {
        try {
            const gameRef = doc(db, 'games', gameid);

            await updateDoc(gameRef, {
                "gameState.phase": 'phase1',
                "gameState.mc": 0
            });

            console.log(`Game ${gameid} locked successfully`);
        } catch (error) {
            console.error('Error locking game:', error);
        }
    };



    if (phase === 'open') {
        return (
            <div>
                <OpenGame gameCode={gameCode} players={players} gameId={gameid} db={db}/>
                <div className="d-flex justify-content-center">
                    <Button variant="success" onClick={handleLockGame}>
                        Start Game
                    </Button>
                </div>
            </div>
        );
    } else if (phase === 'phase1') {
        return (
            <MainScreen players={players}
                        app={app}
                        db={db}
                        gameId={gameid}/>
        );
    } else if (phase === "halftime") {
        return(
            <Halftime gameId={gameid} app={app} db={db} />
        );
    } else if (phase === "phase2") {
        return (
            <MainScreen players={players}
                        app={app}
                        db={db}
                        gameId={gameid} />
        )
    } else if (phase === "endgame") {
        return (
            <MainScreen players={players}
                        app={app}
                        db={db}
                        gameId={gameid} />
        )
    }
};

export default Game;


