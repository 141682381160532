// WhoWroteThis.js
import React, { useEffect, useState } from 'react';

import { doc,
    onSnapshot,
    updateDoc } from "firebase/firestore";
import ResponseCard from '../ResponseCard/ResponseCard';
import WaitingComponent from '../WaitingComponent/WaitingComponent';

import {advanceNextRound} from "../../utils";

const WhoWroteThis = ({ gameID, playerId, isMC, app, db, subphase}) => {
    const [allResponsesIn, setAllResponsesIn] = useState(false);
    const [responses, setResponses] = useState([]);
    const [showButtons, setShowButtons] = useState(true);
    const [phase, setPhase] = useState('');
    const [isMyTurn, setIsMyTurn] = useState(false);
    const [gameData, setGameData] = useState({});
    const [gameRef, setGameRef] = useState(null);

    // 1. Establish if it's 'my' turn.
    // 2. If it is, then show the responses and the buttons, if they exist.
    // 3. If it's not, then show the waiting component.

    // Listen for all responses to be in
    // TODO: But it doesn't listen for all responsees to be in...
    //

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    useEffect(() => {
        const gameRef = doc(db, 'games', gameID);
        setGameRef(gameRef);

        const unsubscribe = onSnapshot(gameRef, (docSnapshot) => {
            console.log('WhoWroteThis.jsx: read')
            const data = docSnapshot.data();
            setGameData(data);

            if (data) {
                let localResponses = data.gameState.currentResponses;
                shuffleArray(localResponses);
                setResponses( localResponses );
                setAllResponsesIn(data.gameState.allResponsesIn || false);
                setPhase(data.gameState.phase || '');
                const whoseGuessID= data.players[data.gameState.whoseGuess];


                if (whoseGuessID.id === playerId) {
                    setIsMyTurn(true);
                }
            }
        });

        return () => unsubscribe();
    }, [db, gameID, isMC, playerId]);

    // Handle functions for best answer and guess...

    const handleBestAnswer = async (playerID) => {

        const players = gameData.players || [];

        const playerIndex = players.findIndex(player => player.id === playerID);


        const bestAnswerPoints = gameData.gameState.phase === "phase2" ? 3 : 1;
        if (playerIndex >= 0) {
            const updatedPlayers = [...players.slice(0, playerIndex), {
                ...players[playerIndex],
                bestAnswerPoints: players[playerIndex].bestAnswerPoints + bestAnswerPoints
            },
                ...players.slice(playerIndex + 1)
            ];

            const updatedCurrentResponses = gameData.gameState.currentResponses.map((response) => {
                if (response.playerID === playerID) {
                    // Add the "bestAnswer" field with the value true to the matching object
                    return { ...response, bestAnswer: true };
                } else {
                    // Keep other objects unchanged
                    return response;
                }
            });
            updateDoc(gameRef, {
                "players": updatedPlayers,
                "gameState.currentResponses": updatedCurrentResponses
            }).then(r => {

            })

            if (phase === "phase2") {
                advanceNextRound(gameRef, gameData)
            }

            setShowButtons(true);
        }
    };

    const handleGuess = async (playerID, response) => {
        console.log('handleGuess', playerID, response)
        const players = gameData.players || [];
        const whoseGuess = gameData.gameState.whoseGuess;
        const playerIndex = players.findIndex(player => player.id === playerID);
        // ^^ when we gave the points to the player who wrote the response.
        console.log('handleGuess', playerID, response)
        console.log('players[playerIndex].name', players[playerIndex].name);
        console.log('response.playerName', response.playerName);
        if (playerIndex >= 0 && players[playerIndex].name === response.playerName) {
            // correct guess
            console.log('points to', players[whoseGuess].name);
            const updatedPlayers = [
                ...players.slice(0, whoseGuess),
                {
                    ...players[whoseGuess],

                    correctGuessPoints: players[whoseGuess].correctGuessPoints + 1
                },
                ...players.slice(whoseGuess + 1)
            ];

            const updatedCurrentResponses = gameData.gameState.currentResponses.map(
                (response) => {
                    if (response.playerID === playerID) {
                        // Add the "bestAnswer" field with the value true to the matching object
                        return { ...response,
                            correctlyGuessed: true };
                    } else {
                        // Keep other objects unchanged
                        return response;
                    }
            });
            const responseArchive = gameData.responseArchive || [];
            const newResponseArchive = [...responseArchive, response];
            const newResponses = gameData.gameState.currentResponses;
            const newMCIndex = (newResponses.length === 0) ? (gameData.gameState.mc + 1) % players.length : gameData.gameState.mc;
            const newWhoseGuess = (newResponses.length === 0) ? newMCIndex : whoseGuess;
            const currentQuestion = (newResponses.length === 0) ? gameData.gameState.currentQuestion : null;
            const round = ((newMCIndex === 0) && (newResponses.length === 0)) ? gameData.gameState.round + 1 : gameData.gameState.round;

            await updateDoc(gameRef,{
                "responseArchive": newResponseArchive,
                "gameState.currentResponses": updatedCurrentResponses,
                "currentQuestion": currentQuestion,
                "players": updatedPlayers,
                "gameState.round": round,
                "gameState.whoseGuess": newWhoseGuess,
                "gameState.mc": newMCIndex,
                "gameState.mcHasGuessed": false,
                "gameState.allResponsesIn": false,

            });

            if (phase === "phase2") {

                const phase2cards = gameData.phase2cards || {};
                const anyEmptyArray = Object.values(phase2cards).some((arr) => arr.length === 0);
                setPhase( ((phase === "phase2") && (anyEmptyArray)) ? "endgame" : phase )

            }




            if (newResponses.length) {
                setShowButtons(true);
            }
        } else {
            const newWhoseGuess = (whoseGuess + 1) % players.length;

            await updateDoc(gameRef, { "gameState.whoseGuess": newWhoseGuess });

            setIsMyTurn(false);
            // We're back to the MC so advance the turn
            if ((whoseGuess + 1) % players.length === gameData.gameState.mc) {
                console.log("We're back to the MC so advance the turn");
                const responseArchive = gameData.responseArchive || [];
                const newResponseArchive = [...responseArchive, ...gameData.gameState.currentResponses];
                const nextMCIndex = (gameData.gameState.mc + 1) % players.length;
                const nextWhoseGuess = nextMCIndex;
                const round = ((nextMCIndex === 0)) ?
                    gameData.gameState.round + 1 : gameData.gameState.round;

                const gameState = {
                    allResponsesIn: false,
                    currentQuestion: '',
                    currentResponses: [],
                    mc: nextMCIndex,
                    nextTurn: true,
                    phase: phase,
                    round: round,
                    whoseGuess: nextWhoseGuess,
                }

                await updateDoc(gameRef, {
                    responseArchive: newResponseArchive,
                    gameState: gameState
                });

                setShowButtons(true);
            }
        }
    };


    return (
        isMyTurn ? (
            <div>
                {responses.map((response, index) => (
                    <ResponseCard
                        key={index}
                        response={response}
                        allResponsesIn={allResponsesIn}
                        isMC={isMC}
                        showButtons={allResponsesIn}
                        handleBestAnswer={handleBestAnswer}
                        handleGuess={handleGuess}
                        responses={responses}
                        subphase={subphase}
                        gameData={gameData}

                    />
                ))}

                {allResponsesIn && !isMC && !showButtons && <WaitingComponent />}
            </div>
        ) : (
            <WaitingComponent />
        )
    );
};

export default WhoWroteThis;
