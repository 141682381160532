import { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import {arrayUnion, doc, updateDoc} from 'firebase/firestore';
import WhoWroteThis from "./WhoWroteThis/WhoWroteThis";
import {advanceNextRound, getSubPhase} from "../utils";
import {v4 as uuidv4} from "uuid";
import {jesusTakeTheWheel} from "../jesusAnswers";

const QuestionForm = ({ gameID, playerID, isMC, app, db, gameData}) => {
    const [question, setQuestion] = useState('');
    const [subPhase, setSubPhase] = useState("not loaded");
    const [response, setResponse] = useState('');
    const [mcResponseIn, setMCResponseIn] = useState(false);
    const gameRef = doc(db, 'games', gameID);

    useEffect(() => {
        // Check if subPhase is "not loaded" or needs to be updated
        if (gameData) {
            setSubPhase(getSubPhase(gameData.gameState));
            console.log("subPhase: ", subPhase);
            // console.log("subPhase: ", getSubPhase(gameData.gameState));
        }
        if ((gameData.gameState.phase === "phase2") &&
            (subPhase === "chooseWhoWroteWhat" ))
        {
            advanceNextRound(gameRef, gameData);
        }
    }, [gameData, gameRef, subPhase]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedData = {
            questions: arrayUnion(question),
            "gameState.currentQuestion": question,
        };

        try {
            await updateDoc(gameRef, updatedData);
            // Reset the form
            setQuestion("");
            setSubPhase("awaitingResponses"); // manually set because we
            // turned off listener when subphase==="composeQuestion"

        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    const handleResponseSubmit = async (e) => {
        e.preventDefault();

        // Check if the response is not empty
        if (response.trim() === '') {
            // You can display an error message or handle it in your preferred way
            console.log('Response cannot be blank');
            return; // Exit the function without submitting
        }

        try {
            const gameRef = doc(db, 'games', gameID);
            const i = gameData.playerIDs.indexOf(playerID);

            const data = {
                playerID: playerID,
                playerName: gameData.players[i].name,
                response: response,
                id: uuidv4(), // Generate a new UUID for the response
            };

            console.log("response data: ", data);
            await updateDoc(gameRef, {
                "gameState.currentResponses": arrayUnion(data),
            });

            setMCResponseIn(true);
        } catch (error) {
            console.error('Error adding response:', error);
        }
    };

    const handleJesusTakeTheWheel = async (e) => {
        const jesusResponse = jesusTakeTheWheel();;
        try {
            const gameRef = doc(db, 'games', gameID);
            const i = gameData.playerIDs.indexOf(playerID);

            const data = {
                playerID: playerID,
                playerName: gameData.players[i].name,
                response: jesusResponse,
                id: uuidv4(), // Generate a new UUID for the response
            };

            console.log("response data: ", data);
            await updateDoc(gameRef, {
                "gameState.currentResponses": arrayUnion(data),
            });

            setMCResponseIn(true);
        } catch (error) {
            console.error('Error adding response:', error);
        }
    };
    return (
        <div>
        <>
            {(subPhase === "composeQuestion")  && (
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder="Enter your question here"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            style={{ marginBottom: '20px' }}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>

                </Form>
            )}
            {(subPhase === "awaitingResponses") &&
                (!mcResponseIn) &&
                (gameData.gameState.phase === "phase1" ) && (
                <Form onSubmit={handleResponseSubmit}>
                    <Form.Group>
                        {/*<Form.Label>Your response:</Form.Label> can't see this anyway*/}
                        <Form.Control type="text"
                                      placeholder="Enter your response here"
                                      value={response}
                                      onChange={(e) => setResponse(e.target.value)}
                                      style={{ marginBottom: '20px' }}/>
                    </Form.Group>
                    <br />
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    <Button
                        variant="danger"  // Choose an appropriate variant
                        style={{ marginLeft: '10px' }}  // Add spacing if needed
                        onClick={handleJesusTakeTheWheel}  // Define the click handler function
                    >
                        Jesus Take the Wheel
                    </Button>
                </Form>
            )}
            {(subPhase === "chooseWhoWroteWhat" || subPhase === "chooseBestResponse") && (
                <WhoWroteThis gameID={gameID}
                              playerId={playerID}
                              isMC={isMC}
                              app={app}
                              db={db}
                              subphase={subPhase}/>
            )}


        </>

        </div>
    );
};

export default QuestionForm;
